<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-3 col-12">Recent App Users List</div>
    <div class="col-lg-9 col-12
        text-end
        d-flex
        justify-content-end">
      <div>
        <InputSwitch class="me-3" v-model="ApiLoadingSwitch" @change="switchLoadingStatus()" />
              <button type="button" title="Refresh" class="btn custom-outline-view-btn me-3"
                @click="this.getAppUsageByVotersList({ page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: 1 });">
                <i class="pi pi-refresh call-btn-color"></i>
              </button>
      </div>
      <div class="datatable-input-filter">
        <Multiselect v-model="filterlistby" :options="filterlistbyList" :searchable="false" label="label"
          placeholder="Select" class="multiselect-custom text-capitalize me-0" :canDeselect="false" :canClear="false"
          :closeOnSelect="true" :object="false" :style="{ width: '360px' }" @select="applyFilter()" />
      </div>
      <button type="button" @click="ticketFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="appInstalledList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
      :rows="items_per_page" :lazy="true" dataKey="ak1" :rowHover="true" :totalRecords="totalRecords"
      @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="updateddate" header="Last Used Date" headerStyle="width: 16%" bodyStyle="width: 16%">
        <template #body="{ data }">
          <div class="from-label-value">{{ format_timestamp(data.ak224) }}</div>
        </template>
      </Column>

      <Column field="modelname" header="Mobile Brand / Modal Name" headerStyle="width: 24%" bodyStyle="width: 24%">
        <template #body="{ data }">
          <div class="w-100">
            <div class="label-subheading text-capitalize cursor-default"><span>Brand : </span><span
                class="text-muted">{{ data.ak227 ? data.ak227 : "N/A" }}</span></div>
            <Divider type="dashed" v-if="data.ak226" />
            <div class="label-subheading cursor-default" v-if="data.ak226"><span>Modal : </span><span
                class="text-muted">{{ data.ak226 ? data.ak226 : "N/A" }}</span></div>
          </div>
        </template>
      </Column>
      <Column field="modelname" header="Mobile OS Version" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div class="w-100">
            <div class="label-subheading cursor-default">
              <span v-if="data.ak228 == 1">Android Version : </span>
              <span v-if="data.ak228 == 2">iOS Version : </span>
              <span>
                <span v-if="data.ak230" class="text-muted cursor-default" title="Version">{{ data.ak230 ? data.ak230 :
                  "N/A" }}</span>
                <span v-else>N/A</span>
              </span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="name" header="Voter Name" headerStyle="width: 24%" bodyStyle="width: 24%">
        <template #body="{ data }">
          <div class="label-subheading text-capitalize">
            {{ data.ak5 ? data.ak5 : "N/A" }}</div>
        </template>
      </Column>
      <Column field="installeddate" header="App Installed Date" headerStyle="width: 16%" bodyStyle="width: 16%">
        <template #body="{ data }">
          <div class="from-label-value">{{ format_timestamp(data.ak229) }}</div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="ticketfiltermodalsflag">
    <div class="modal-sm modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="ticketFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">App Installed From Date</label>
                <Calendar v-model="filter.installedfromdate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false"
                  :monthNavigator="true" :yearNavigator="true" :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="filter.installedtodate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :minDate="filter.installedfromdate"
                  :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">App Last Used From Date</label>
                <Calendar v-model="filter.updatedfromdate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false"
                  :monthNavigator="true" :yearNavigator="true" :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="filter.updatedtodate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :minDate="filter.updatedfromdate" :maxDate="todaysDate"
                  :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Name</label>
                <input type="text" v-model="filter.votername" @keypress="isLetterWithSpace($event)"
                  class="form-control text-capitalize" placeholder="Enter Name" maxlength="30" autocomplete="off" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Mobile Brand Name</label>
                <input type="text" v-model="filter.brandname" class="form-control text-capitalize"
                  placeholder="Enter Brand Name" maxlength="30" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">Type of Mobile</label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="filter.typemobile" name="callstatus-option"
                    id="typeandroid" autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="typeandroid">Android</label>
                  <input type="radio" class="btn-check" v-model="filter.typemobile" name="callstatus-option"
                    id="typeios" autocomplete="off" value="2" />
                  <label class="btn" for="typeios">iOS</label>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">OS Version</label>
                <input type="text" v-model="filter.mobileversion" class="form-control text-capitalize"
                  placeholder="Enter OS Version" maxlength="30" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn" @click="
                applyFilter()" :disabled="filter == ''">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" :disabled="filter == ''" @click="resetVoterFilter()">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      appInstalledList: null,
      totalRecords: 0,
      page_no: 0,
      items_per_page: 30,
      loading: false,

      filterlistby: 1,
      filterlistbyList: [
        { value: 1, label: "Today Users" },
        { value: 2, label: "Yesterday's Users" },
        { value: 3, label: "This Week's Users (From Monday Till Now)" },
        { value: 4, label: "This Month's Users (From 1st Till Now)" },
        { value: 0, label: "All Users" },
      ],
      ticketfiltermodalsflag: false,

      searchscamdate: null,
      searchreporteddate: null,
      maxDate: new Date(),
      filter: {
        installedfromdate: null,
        installedtodate: null,
        updatedfromdate: null,
        updatedtodate: null,
        votername: '',
        brandname: '',
        typemobile: '',
        mobileversion: '',
      },
      todaysDate: new Date(),
      ApiLoadingSwitch: false,
    };
  },
  beforeUnmount() {
    clearInterval(this.timer5);
  },
  mounted() {
    this.getAppUsageByVotersList({ date_type: 1 });
  },

  ApiService: null,
  interval: null,

  created() {
    this.ApiService = new ApiService();
  },

  methods: {

    getAppUsageByVotersList(event) {
      this.loading = true;
      this.ApiService.getAppUsageByVotersList(event).then((items) => {
        if (items.success == true) {
          this.appInstalledList = items.data;
          this.totalRecords = items.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.appInstalledList = null;
          this.totalRecords = 0;
        }
      });
    },

    changePage(event) {
      this.page_no = event.page;
      this.getAppUsageByVotersList({
        page_no: this.page_no,
        items_per_page: this.items_per_page,
      });
    },
    ticketFilterModalOpen() {
      this.ticketfiltermodalsflag = true;
    },
    ticketFilterModalClose() {
      this.ticketfiltermodalsflag = false;
    },
    applyFilter() {
      this.ticketfiltermodalsflag = false;
      this.getAppUsageByVotersList({
        page_no: this.page_no,
        items_per_page: this.items_per_page,
        ak229from: this.filterinstalledfromdate ? moment(this.filterinstalledfromdate).format("YYYY-MM-DD") : null,
        ak229to: this.filter.installedtodate ? moment(this.filter.installedtodate).format("YYYY-MM-DD") : null,
        ak224from: this.filter.updatedfromdate ? moment(this.filter.updatedfromdate).format("YYYY-MM-DD") : null,
        ak224to: this.filter.updatedtodate ? moment(this.filter.updatedtodate).format("YYYY-MM-DD") : null,
        ak5: this.filter.votername,
        ak227: this.filter.brandname,
        ak228: this.filter.typemobile,
        ak230: this.filter.mobileversion,
        date_type: this.filterlistby
      });
    },
    resetVoterFilter() {
      this.filter = {},
        this.getAppUsageByVotersList({
          page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: 1
        });
      this.ticketfiltermodalsflag = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    switchLoadingStatus() {
      if (this.ApiLoadingSwitch == true) {
        this.timer5 = window.setInterval(() => {
          this.reloadAppUserList({
            page_no: this.page_no,
            items_per_page: this.items_per_page,
            date_type: 1
          });
        }, 2000);
      } else {
        clearInterval(this.timer5);
      }
    },
    reloadAppUserList(event) {
      this.ApiService.getAppUsageByVotersList(event).then((items) => {
        if (items.success == true) {
          this.appInstalledList = items.data;
          this.totalRecords = items.count;
        } else {
          this.appInstalledList = null;
          this.totalRecords = 0;
        }
      });
    },
  },
};
</script>

<style scoped></style>